import React from "react"
import { Grid, Col, Row } from "react-styled-flexboxgrid"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import ImageMain from "../components/imageMain"
import SpecimenUm from "../components/specimenUm"
import FoliosUm from "../components/foliosUm"
import FoliosDois from "../components/foliosDois"
import Folios from "../components/folios"
import SpecimenDois from "../components/specimenDois"
import Ligature from "../components/ligature"
// import ImgSet from "../components/imgset"

// import License from "../docs/LICENSE.pdf"
// import Specimen from "../docs/megalove-type-specimen.pdf"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Fontoira" />
      <Nav />
      <div className="image-main">
        <Grid>
          <Row center="xs">
            <Col md={6} sm={10} xs={12}>
              <ImageMain />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div class="image-main__claim">
                Fontoira é unha fonte tipográfica inspirada no estilo tradicional de foliar os barcos nos portos d’O Grove e Meloxo.
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
      <div className="about" id="manifesto">
        <Grid fluid>
          <Row center="xs">
            <Col xs={12}>
              <h1 className="about__title">Manifesto</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="about__info">
                <p>
                  Desde hai moitos anos era habitual ver nas augas do Grove os
                  barcos afoliados con unas letras moi traballadas, ben
                  representativas. Tal como recollen as fotografías antigas,
                  deducimos que estes folios non eran pintados con plantillas xa
                  que os remates e rebiricoques sempre eran distintos.
                </p>
                <p>
                  A medida que avanzaron os anos, e o afoliar os barcos foi
                  deixando ser un dos traballos dos mariñeiros e armadores,
                  pasando a seren rotulados de maneira mecánica, este tipo de
                  letra foi deixando de ser tan habitual.
                </p>
                <p>
                  Empezamos a falar hai uns anos sobre o crear unha tipografía
                  que recollera o ADN, a esencia, deses folios que levabamos vendo
                  desde pequenos, nas amuras das embarcacións que encheron os
                  nosos portos. Desta maneira pretendemos contribuír a manter
                  este sinal de identidade, de cultura e do patrimonio marítimo.
                </p>
                <p>
                  Cando investigamos un pouco sobre os folios dos barcos do
                  Grove, era recorrente a frase: «ese folio fíxoo <strong>Raúl
                  Fontoira</strong>». Non foron poucos os armadores que se dirixiron a el
                  para pedirlle que lle fixera a man o folio das súas
                  embarcacións, e el sempre accedeu, practicando a súa arte para
                  o lettering e mantendo vivo o seu quiñón de identidade,
                  cultura e patrimonio marítimo; por iso esta tipografía leva o
                  seu nome.
                </p>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
      <FoliosUm />
      <div className="about">
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <div className="about__info">
                <p>
                  Ricardo Fontoira, aínda que todo o mundo lle chama Raúl, naceu
                  n'O Grove en 1938; foi á escola ata os 17 anos, pero un par de
                  anos antes xa tiña comezado a debuxar letras de maneira
                  autodidacta xa que tiña especial gusto por rotular, sen
                  consultar nunca ningún libro ou manual.
                </p>
                <p>
                  Ao saír da escola foi «polo mundo adiante» a navegar, pero
                  mantivo a súa afección ao mundo das letras; en 1970 andando
                  embarcado nunha plataforma estadounidense, o capitán preguntou
                  a tripulación si alguén se comprometía a rotular varias
                  balizas e áncoras durante a travesía entre Rotterdam e Trinidad
                  e Tobago, un dos compañeiros que sabía da man do señor Raúl
                  propúxoo para facer o encargo. Cando rematou, o capitán
                  preguntoulle que oficio tiña: «eu son mariñeiro»,
                  respondeulle; e o oficial díxolle que si foran para os Estados
                  Unidos o desembarcaría, xa que coas mans que tiña podería
                  gañar moito carto como rotulista.
                </p>
                <p>
                  De volta da emigración, o señor Raúl mercou bateas e un barco
                  de segunda man, o Pato, e fíxolle el mesmo a letras. O folio
                  dos barcos do Grove é o Vill-4, así que pensou «en facer un V
                  fina, sin botarme na V de sempre, e parece que foi unha
                  explosión que lle gustou a todo o mundo». A partir de aí
                  moitos compañeiros do mar empezaron a chamalo para que lles
                  afoliara o barco cando o tiñan no carro, e el sempre accedeu a
                  facelo de xeito altruísta, tanto n'O Grove como noutras vilas
                  da ría.
                </p>
                <p>
                  A día de hoxe segue a rotular, coma sempre «a pulso, sen
                  marcar, namáis que unha raia para que vaian máis ou menos
                  dereitas, e sen copiarlle nunca a ninguén, todas sacadas da miña
                  mente».
                </p>
                <p>
                  Creamos a fonte tipográfica Fontoira para conservar este
                  estilo de afoliar os barcos nos portos do Grove e de Meloxo,
                  xa que co paso do tempo se converteron en parte da identidade
                  da cultura marítima local. Pero tamén para recoñecer e
                  conservar a arte e o traballo que saíu da mente e das mans do
                  señor Raúl Fontoira.
                </p>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
      <FoliosDois />
      <SpecimenUm />
      <Folios />
      <Grid fluid>
        <Row center="xs">
          <Col md={8} sm={10} xs={12}>
            <SpecimenDois />
          </Col>
        </Row>
      </Grid>
      <Grid fluid>
        <Row center="xs">
          <Col xs={12}>
            <div className="ligature">
              Inclúe ligaduras para estilizar as provincias marítimas
            </div>
          </Col>
        </Row>
        <Row center="xs">
          <Col md={8} sm={10} xs={12}>
            <Ligature />
          </Col>
        </Row>
      </Grid>
      <div id="download" className="download">
        <Grid fluid>
          <Row center="xs">
            <Col xs={12}>
              <div className="download__content">
                A licenza de uso de Fontoira é gratuíta para uso persoal ou fins non lucrativos. Para uso comercial débese aportar a cantidade económica que se considere apropiada.
              </div>
              <div className="download__button">
                <a className="button" href="https://gum.co/fontoira">Descarga</a>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
      <div className="authors">
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <h1 className="authors__title">
                Sobre os autores
              </h1>
              <div className="authors__content">
                Detrás desta fonte están dous mecos: Lino Prieto (nacido no barrio de Katanga pero exiliado ao Montiño) e Rubén Prol (do barrio d'O Con). Puxemos moita ilusión en dixitalizar o que consideramos un impagable patrimonio gráfico da nosa vila. Despachamos en <a href="mailto:linoeruben@fontoira.gal">linoeruben@fontoira.gal</a>.
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    </Layout>
  )
}

export default IndexPage
